$a-cl: #0000ff;
$a-bg: #ffe2e2;

$b-cl: #ffffff;
$b-bg: #000000;

$c-cl: #DA4E00;
$c-bg: #F5E1CE;

$d-cl: #FFD3D3;
$d-bg: #302449;

$e-cl: #CF0000;
$e-bg: #EDEDED;

$f-cl: #D6F0FF;
$f-bg: #ff4da2;

$g-cl: #B7A5FD;
$g-bg: #DED6FF;

$h-cl: #c4fff3;
$h-bg: #0b3f35;

$i-cl: #e84e3c;
$i-bg: #e8dad9;

$j-cl: #ff4480;
$j-bg: #2c0303;

$k-cl: #6fd1ff;
$k-bg: #d6f2ff;

$l-cl: #fff79b;
$l-bg: #000000;

$m-cl: #bd2828;
$m-bg: #d8c9be;

$n-cl: #ffd1ff;
$n-bg: #2d4be7;

$o-cl: #403353;
$o-bg: #cbbcce;

$p-cl: #E9E9E9;
$p-bg: #F46E6E;

$q-cl: #7BA4B1;
$q-bg: #E0F9ED;

$r-cl: #FDA5B5;
$r-bg: #864E0C;

$s-cl: #FFD057;
$s-bg: #E0F0F9;

$t-cl: #CECDFF;
$t-bg: #541919;

$u-cl: #083C0D;
$u-bg: #F8E0F9;

$v-cl: #DDF3DF;
$v-bg: #24173F;

$w-cl: #FF9494;
$w-bg: #F9E2ED;

$x-cl: #353E8F;
$x-bg: #111C86;

$y-cl: #FFBA94;
$y-bg: #F9F2E2;

$z-cl: #DD95FF;
$z-bg: #FFE870;

$zero-cl: #000000;
$zero-bg: #A0B4FE;

$one-cl: #3D1058;
$one-bg: #FA71CD;

$two-cl: #DFD4E8;
$two-bg: #fbfcdb;

$three-cl: #E9E9E9;
$three-bg: #1B1B28;

$four-cl: #FF9494;
$four-bg: #FFFBF2;

$five-cl: #FFEDED;
$five-bg: #3870FF;

$six-cl: #256433;
$six-bg: #FAEFC8;

$seven-cl: #FFC513;
$seven-bg: #730039;

$eight-cl: #1FA774;
$eight-bg: #FFD1DF;

$nine-cl: #C9EFFF;
$nine-bg: #fc00ff;

