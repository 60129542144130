@import "reset";
@import "typography";
@import "textcolors";
@import "mixins";


$body-font: "Bandeins Strange";
$body-size: 30px;
$primary-color: #3900f5;

$mobile: 640px;


@keyframes rotate {
    0% {
        transform: rotate(15deg);
    }
    100% {
        transform: rotate(-15deg);
    }
}





body {
    font-family: $body-font;
    line-height: 1.5;
    font-size: $body-size;
    text-align: center;
    background-color: $primary-color;
    overflow: hidden;
}


ul.nav {
    position: sticky;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    padding: 10px;
    z-index: 5;
    background-color: #ffffff;

    @media screen and (max-width: $mobile) {
        font-size: 24px;
    }

    li {
        display: inline;

        a {
            display: inline-block;
            text-decoration: none;
            color: #000000;
  
            @include variable-font(100,100);
            @include fade-transition(font-variation-settings);

            &:hover {
                @include variable-font(900,200);
            }

            &.separate {
                opacity: 0.5;
                padding:0 10px;
                
                &:hover {
                    @include variable-font(100,100);
                }
            }
        }  
        
        &.active a {
            @include variable-font(900,900);
            opacity: 0.5;
        }

        &.restricted a {
            opacity: 0.2;

            &:hover {
                @include variable-font(100,100);
            }
        }
    }
}


canvas {
    @include center();
    @media screen and (max-width: $mobile) {
        transform: scale(0.7, 0.7) translate(-72%, -72%);
    }
}

section {
    width: 100vw;
    min-height: 100vh;
    padding: 60px;

    &#a {
        @include colors($a-cl,$a-bg);
    }
    &#b {
        @include colors($b-cl,$b-bg);
    }
    &#c {
        @include colors($c-cl,$c-bg);
        height: 1000px;
    }
    &#d {
        @include colors($d-cl,$d-bg);
    }
    &#e {
        @include colors($e-cl,$e-bg);
    }
    &#f {
        @include colors($f-cl,$f-bg);
    }
    &#g {
        @include colors($g-cl,$g-bg);
    }
    &#h {
        @include colors($h-cl,$h-bg);
    }
    &#i {
        @include colors($i-cl,$i-bg);
    }
    &#j {
        @include colors($j-cl,$j-bg);
    }
    &#k {
        @include colors($k-cl,$k-bg);
    }
    &#l {
        @include colors($l-cl,$l-bg);
    }
    &#m {
        @include colors($m-cl,$m-bg);
    }
    &#n {
        @include colors($n-cl,$n-bg);
    }
    &#o {
        @include colors($o-cl,$o-bg);
        @include rotate-animation(2s);
    }
    &#p {
        @include colors($p-cl,$p-bg);
        transform: rotate(-15deg);
    }
    &#q {
        @include colors($q-cl,$q-bg);
    }
    &#r {
        @include colors($r-cl,$r-bg);
    }
    &#s {
        @include colors($s-cl,$s-bg);
    }
    &#t {
        @include colors($t-cl,$t-bg);
    }
    &#u {
        @include colors($u-cl,$u-bg);
    }
    &#v {
        @include colors($v-cl,$v-bg);
    }
    &#w {
        @include colors($w-cl,$w-bg);
    }
    &#x {
        @include colors($x-cl,$x-bg);
    }
    &#y {
        @include colors($y-cl,$y-bg);
    }
    &#z {
        @include colors($z-cl,$z-bg);
    }
    &#zero {
        @include colors($zero-cl,$zero-bg);
    }
    &#one {
        @include colors($one-cl,$one-bg);
        background-image: linear-gradient(to top, #c471f5 0%, #fa71cd 100%);    }
    &#two {
        @include colors($two-cl,$two-bg);
        background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
    }
    &#three {
        @include colors($three-cl,$three-bg);
    }
    &#four {
        @include colors($four-cl,$four-bg);
    }
    &#five {
        @include colors($five-cl,$five-bg);
    }
    &#six {
        @include colors($six-cl,$six-bg);
    }
    &#seven {
        @include colors($seven-cl,$seven-bg);
    }
    &#eight {
        @include colors($eight-cl,$eight-bg);
    }
    &#nine {
        @include colors($nine-cl,$nine-bg);
        background-image: linear-gradient(to right, #00dbde 0%, #fc00ff 100%);
    }
}

div.container {
    width: 100vw;
    height: 100vh;
    

    &.o {
        @include colors($o-cl,$o-bg);
    }
    &.p {
        @include colors($p-cl,$p-bg);
    }
    &.q {
        @include colors($q-cl,$q-bg);
    }
    &.r {
        @include colors($r-cl,$r-bg);
    }
    &.s {
        @include colors($s-cl,$s-bg);
    }
}

body.intro {
    overflow: hidden auto;
    background-color: $primary-color;
    color: #ffffff;
    transition: background-color 0.5s;

    section {
        color: $primary-color;
        background-color: white;
    }

    canvas#defaultCanvas0 {
        position: fixed;
        z-index: -1;
        opacity:0.3;
        transition: opacity 0.05s;

        @media screen and (max-width: $mobile) {
            transform: scale(0.5, 0.5) translate(-100%, -100%) rotate(90deg);
        }
    }

    span {
        font-size: 20px;
        position: absolute;
        top: 40px;
        right: 60px;

        &:hover {
            cursor: pointer;
        }

        @media screen and (max-width: $mobile) {
            top: unset;
            bottom: 40px;
            right: 40px;
        }
    }

}


div.letters {
    // position: relative;
    // top: 75vh;
    // left: 50%;
    // transform: translate(-50%, 0);

    @include center();

    width: 720px;

    font-size: 80px;
    text-align: center;

    display: grid;
    grid-template-columns: auto auto auto auto auto auto;

    @media screen and (max-width: $mobile) {
        width: 95%;
        font-size: 60px;
        grid-template-columns: auto auto auto auto;
    }

    div {
        width:120px;
        height: 120px;
        color: #ffffff;

        @media screen and (max-width: $mobile) {
            width: 100%;
            height: 100%;
        }


        a {
            text-decoration: none;
            color: #ffffff;
            @include variable-font(100,100);
            @include fade-transition(font-variation-settings);

            &:hover {
                @include variable-font(900,200);
            }
        }
    }
}

div.about {
    position: absolute;
    width: 100vw;
    height:100vh;


    background-color: white;
    color: $primary-color;

    opacity: 0;
    @include fade-transition(opacity);
    z-index: -10;

    &.show {
        opacity: 1;
        z-index: 10;
    }


    div {
        @include center;
        width:65%;
        padding: 60px;

        @media screen and (max-width: $mobile) {
            width: 80%;
            padding: 20px;
        }


        a {
            text-decoration: none;
            color: $primary-color;
            @include variable-font(900,280);
            @include fade-transition(font-variation-settings);

            &:hover {
                @include variable-font(900,320);
            }
        }
    }

    

    
}

